import React from 'react'
import { Link } from 'react-router-dom';
import { Element } from 'react-scroll';
import DataTalentSectionTitle from '../DataTalentSectionTitle';

const JobseekersEmployerCTA = () => {

  return (
    <Element
      className="data-talent-jobseekers-employer_cta datatalent-section-gap"
    >
      <div className="container eduvibe-animated-shape">
        <div className="row g-5 align-items-center">
          <DataTalentSectionTitle
            classes = "text-center mt-0"
            // slogan = "What You Will Do"
            title = "Are You A Business Looking For Free Resourcing?"
          />
          <div className="col-12 mt-0 text-center">
            <Link to="/programs/business-accelerator" className="edu-btn btn-primary jobseekers-employer_cta">Go To Business Accelerator</Link>
          </div>
        </div>
      </div>
    </Element>
  )
}

export default JobseekersEmployerCTA;