import React from 'react';
import SEO from '../../../common/SEO';
import Layout from '../../../common/Layout';
import BreadcrumbOne from '../../../common/breadcrumb/BreadcrumbOne';
import RegisterProgramForm from '../../../components/form/register-program-form/RegisterProgramForm';
// import { Link } from 'react-router-dom';

const EventData = [
  {
    "id": 1,
    "title": "Health Sciences Data Analytics",
    "image": "health_sciences_data_analytics.webp",
    "website": "https://www.neuraura.com/looop",
    "industry": "Manufacturing, Technology, Hospital, health, wellness & medical",
    "rating": "4.8 Google Rating",
    "duration": "12 Weeks",
    "category": "Data Science, ML & AI",
    "location": "https://www.neuraura.com/looop",
    "going": "140+",
    "company": "Neuraura"
  }
]

const HealthSciencesDataAnalytics = ({
  showRegisterForm,
  setShowRegisterForm,
  company
  }) => {
  const eventItem = EventData[0];
  return (
    <>
      <SEO
      title={ eventItem.title }
      description=""
      />
      <Layout>
        <BreadcrumbOne 
          title="Health Sciences Data Analytics"
          rootUrl="/programs/work-integrated-learning"
          parentUrl="Industry Partner Work Integrated Learning"
          currentUrl="Health Sciences Data Analytics"
        />
        <div className="edu-event-details-area edu-event-details edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img src={`${process.env.PUBLIC_URL}/images/project-details/${ eventItem.image }`} alt="Event Thumb" />
                </div>
              </div>
            </div>
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="content">
                  <h3 className="title">{ eventItem.title }</h3>
                  <p>
                    <strong>What is the main goal for this project?</strong><br></br>
                    Develop a pilot project to illustrate the role of multi-edge computing in Neuraura's long-term vision for LoOoP.<br></br>
                    This project will leverage tools and expertise provided as part of the Bell Edge Canada Challenge including AWS tools and integrate prior work completed on the development of the MyLoOoP app by Centennial College's ARIES team.
                  </p>
                  <p className="mb-0">
                    <strong>What tasks will learners need to complete to achieve the project goal?</strong><br></br>
                  </p>
                  <ul>
                    <li>
                      Review core business strategy documents to put project in context of Neuraura's stage and broader strategic goals
                    </li>
                    <li>
                      Review draft documents on Neuraura's data strategy; update and add to document based on project outcomes and learnings
                    </li>
                    <li>
                      Review information on Bell Canada Challenge and historical case studies
                    </li>
                    <li>
                      Review data flow diagram for prototype of MyLoOoP app; update and add to document based on project outcomes and learnings
                    </li>
                    <li>
                      Develop synthetic data set based on data flow diagram and publically available information
                    </li>
                    <li>
                      Plan, build and test pilot with AWS / Bell Canada platform support
                    </li>
                  </ul>
                  <p className="mb-0">
                    <strong>How will you support learners in completing the project?</strong><br></br>
                  </p>
                  <ul>
                    <li>
                      Weekly review / working meetings with Neuraura's CPO and / or CEO
                    </li>
                    <li>
                      Background information on company strategy and underlying technologies
                    </li>
                    <li>
                      Data science tools and expertise through Bell Canada Challenge
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="eduvibe-sidebar">
                  <div className="eduvibe-widget eduvibe-widget-details">
                    <h5 className="title">ABOUT INDUSTRY PARTNER</h5>
                    <div className="widget-content">
                      <ul>
                        {/* <li><span><i className="icon-Campus"></i> { eventItem.company }</span></li> */}
                        <li><span><i className="icon-Campus"></i> <a href={eventItem.location}>{ eventItem.company }</a></span></li>
                        <li><span><i className="icon-presentation"></i> { eventItem.category }</span></li>
                        {/* <li><span><i className="icon-map-pin-line"></i> {company ? <a href={eventItem.location}>Website</a> : eventItem.location}</span></li> */}
                        <li>
                          LoOoP by Neuraura: Restoring Women's Reproductive and Metabolic Health with a Wearable Bioelectronic Device Neuraura is bringing to market the first effective, widely accessible treatment for PCOS as an over-the-counter, affordable device that augments the body's natural processes rather than just masking the symptoms.
                        </li>
                      </ul>
                      {!company && <div className="read-more-btn mt--45">
                        <a className="edu-btn btn-secondary text-center" href="https://form.jotform.com/m2mtech/wil-programs-sign-up">Register Now<i className="icon-arrow-right-line-right"></i></a>
                      </div>}

                      {/* <div className="read-more-btn mt--30 text-center">
                        <div className="eduvibe-post-share">
                          <span>Share: </span>
                          <a className="linkedin" href="/"><i className="icon-linkedin"></i></a>
                          <a className="facebook" href="/"><i className="icon-Fb"></i></a>
                          <a className="twitter" href="/"><i className="icon-Twitter"></i></a>
                          <a className="youtube" href="/"><i className="icon-youtube"></i></a>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {!company && <div className="col-lg-12">
                <div className="read-more-btn text-center">
                  <a className="edu-btn btn-secondary text-center" href="https://form.jotform.com/m2mtech/wil-programs-sign-up">Register Now<i className="icon-arrow-right-line-right"></i></a>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </Layout>
      {showRegisterForm && <RegisterProgramForm setShowRegisterForm={setShowRegisterForm} />}
    </>
  )
}
export default HealthSciencesDataAnalytics;