import React, { useEffect, useLayoutEffect, useRef } from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import IndustryAlignedUpskillingBanner from '../../components/industry-aligned-upskilling/IndustryAlignedUpskillingBanner';
import IndustryAlignedUpskillingAbout from '../../components/industry-aligned-upskilling/IndustryAlignedUpskillingAbout';
import IndustryAlignedUpskillingWhatWeDo from '../../components/industry-aligned-upskilling/IndustryAlignedUpskillingWhatWeDo';
import IndustryAlignedUpskillingHowWeDoIt from '../../components/industry-aligned-upskilling/IndustryAlignedUpskillingHowWeDoIt';
import IndustryAlignedUpskillingWhatYouWillLearn from '../../components/industry-aligned-upskilling/IndustryAlignedUpskillingWhatYouWillLearn';
import IndustryAlignedUpskillingPathsYouCanFollow from '../../components/industry-aligned-upskilling/IndustryAlignedUpskillingPathsYouCanFollow';
import IndustryAlignedUpskillingSuccessStory from '../../components/industry-aligned-upskilling/IndustryAlignedUpskillingSuccessStory';
import IndustryAlignedUpskillingCategory from '../../components/industry-aligned-upskilling/IndustryAlignedUpskillingCategory';
// import IndustryAlignedUpskillingCourses from '../../components/industry-aligned-upskilling/IndustryAlignedUpskillingCourses';
import TestimonialSectionFour from '../../components/testimonial-section/TestimonialSectionFour';
import IndustryAlignedUpskillingVideoSection from '../../components/industry-aligned-upskilling/IndustryAlignedUpskillingVideoSection';
import NewsLetterOne from '../../components/newsletter/NewsLetterOne';
import FooterOne from '../../common/footer/FooterOne';
import IndustryAlignedUpskillingPrograms from '../../components/industry-aligned-upskilling/IndustryAlignedUpskillingPrograms';
import IndustryAlignedUpskillingStudentsSpotlightBlogs from '../../components/industry-aligned-upskilling/IndustryAlignedUpskillingStudentsSpotlightBlogs';
import RegisterProgramForm from '../../components/form/register-program-form/RegisterProgramForm';
import UserInterestForm from '../../components/form/user-interest-form/UserInterestForm';
import SkillsboostSignUpForm from '../../components/form/skillsboost-form/SkillsboostSignUpForm';
import { scrollIntoViewWithOffset } from '../../utils/scrollToViewWithOffset';
import IndustryAlignedUpskillingPackages from '../../components/industry-aligned-upskilling/IndustryAlignedUpskillingPackages';

const IndustryAlignedUpskilling = ({
  showUserInterestForm,
  setShowUserInterestForm,
  showRegisterForm,
  setShowRegisterForm,
  showSkillsboostSignUpForm,
  setShowSkillsboostSignUpForm
  }) => {

  useEffect(() => {
    const anchor = window.location.hash.split('#')[1];
    if(anchor) {
      scrollIntoViewWithOffset(anchor);
    }
  }, [])

  const GoogleReviews = useRef();

  return (
    <>
      <SEO
        title="M2M Tech"
        description="Join a cohort to collaborate with industry partners and professionals. Revolutionize your learning experience where collaboration, community and personalization meet!"
      />

      <HeaderTwo styles="header-transparent header-style-2" searchDisable />

      <IndustryAlignedUpskillingBanner
        setShowUserInterestForm={setShowUserInterestForm}
        GoogleReviews={GoogleReviews}
      />
      
      <IndustryAlignedUpskillingPackages />
      
      <IndustryAlignedUpskillingPrograms setShowUserInterestForm={setShowUserInterestForm} setShowSkillsboostSignUpForm={setShowSkillsboostSignUpForm} />

      {/* <IndustryAlignedUpskillingAbout /> */}

      <IndustryAlignedUpskillingWhatWeDo />

      {/* <IndustryAlignedUpskillingHowWeDoIt /> */}

      <IndustryAlignedUpskillingWhatYouWillLearn />

      <IndustryAlignedUpskillingPathsYouCanFollow />

      <IndustryAlignedUpskillingCategory />

      {/* <IndustryAlignedUpskillingCourses /> */}

      <IndustryAlignedUpskillingVideoSection />

      <IndustryAlignedUpskillingStudentsSpotlightBlogs />

      <IndustryAlignedUpskillingSuccessStory />

      <TestimonialSectionFour GoogleReviews={GoogleReviews} />

      <NewsLetterOne />

      <FooterOne />

      {showUserInterestForm && <UserInterestForm setShowUserInterestForm={setShowUserInterestForm} />}
      {/* {showRegisterForm && <RegisterProgramForm setShowRegisterForm={setShowRegisterForm} />} */}
      {showSkillsboostSignUpForm && <SkillsboostSignUpForm setShowSkillsboostSignUpForm={setShowSkillsboostSignUpForm} />}
    </>
  )
}

export default IndustryAlignedUpskilling;