import React from 'react'
import AMSectionTitle from '../AMSectionTitle'
import { Element } from 'react-scroll'

const MembershipCareerLaunchGlance = () => {
  return (
    <>
      <Element className="edu-about-area bg-white datatalent-section-gap">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 align-items-center">
            <div className="col-12 mt-0">
              <AMSectionTitle
                classes = "text-left"
                // slogan = "Introduction"
                title = "Unlock Your Full Potential in Data Science, ML, and AI"
              />
              <p className="mb-0 ms-about-description mt-4">
                Through our comprehensive <strong>12-month membership</strong> program, which includes self-paced courses, work-integrated learning projects, and personalized mentorship, we aim to equip you with the skills and connections needed to excel in the ever-evolving tech landscape.<br></br>
                <br></br>Whether you are just starting out or looking to enhance your expertise, our <strong>diverse range</strong> of annual membership options is designed to support your growth and help you achieve your career goals.
              </p>
            </div>
            <div className="col-lg-12 mt--60 p-0">
              <AMSectionTitle
                classes = "text-left"
                // slogan = "Introduction"
                title = "At a glance"
              />
            </div>
          </div>
          <div className="row g-5 ms-glance-card-container">
            <div className="col-12 col-lg-4">
              <div className="ms-glance-card h-100">
                <div className="ms-glance-card-image">
                  <img src={`${process.env.PUBLIC_URL}/images/annual-membership/illustrations/icon_internship.svg`} width={124} alt="illustration" />
                </div>
                <h5 className="mt-5 mb-2">Guaranteed Internship</h5>
                <p className="ms-glance-card-description">Nothing opens more doors than work experience, we ensure you get one in data science or your money back!</p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="ms-glance-card h-100">
                <div className="ms-glance-card-image">
                  <img src={`${process.env.PUBLIC_URL}/images/annual-membership/illustrations/icon_career_coaching.svg`} width={124} alt="illustration" />
                </div>
                <h5 className="mt-5 mb-2">Career Coaching</h5>
                <p className="ms-glance-card-description">Unsure of next steps? Don't worry our career coaches will walk you through every step of the way.</p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="ms-glance-card h-100">
                <div className="ms-glance-card-image">
                  <img src={`${process.env.PUBLIC_URL}/images/annual-membership/illustrations/icon_paid_internship.svg`} width={124} alt="illustration" />
                </div>
                <h5 className="mt-5 mb-2">Get Paid to Work</h5>
                <p className="ms-glance-card-description">Almost too good to be true, but provided internships are guaranteed and all paid.</p>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default MembershipCareerLaunchGlance