import React from 'react';
import SEO from '../../../common/SEO';
import Layout from '../../../common/Layout';
import BreadcrumbOne from '../../../common/breadcrumb/BreadcrumbOne';
import RegisterProgramForm from '../../../components/form/register-program-form/RegisterProgramForm';
// import { Link } from 'react-router-dom';

const EventData = [
	{
		"id": 1,
		"title": "AI for speech enhancement",
		"image": "AI_speech_enhancement_v2.webp",
		"website": "https://heardthat.ai/",
		"industry": "Education, It & computing",
		"rating": "4.8 Google Rating",
		"duration": "12 Weeks",
		"category": "Data Science, ML & AI",
		"location": "https://heardthat.ai/",
		"going": "140+",
		"company": "SingularHearing"
	}
]

const AIForSpeechEnhancement = ({
	showRegisterForm,
	setShowRegisterForm,
	company
	}) => {
	const eventItem = EventData[0];
	return (
		<>
			<SEO
				title={ eventItem.title }
				description=""
			/>
			<Layout>
				<BreadcrumbOne 
					title="AI for speech enhancement"
					rootUrl="/programs/work-integrated-learning"
					parentUrl="Industry Partner Work Integrated Learning"
					currentUrl="AI for speech enhancement"
				/>
				<div className="edu-event-details-area edu-event-details edu-section-gap bg-color-white">
					<div className="container">
						<div className="row g-5">
							<div className="col-lg-12">
								<div className="thumbnail">
									<img src={`${process.env.PUBLIC_URL}/images/project-details/${ eventItem.image }`} alt="Event Thumb" />
								</div>
							</div>
						</div>
						<div className="row g-5">
							<div className="col-lg-7">
								<div className="content">
									<h3 className="title">{ eventItem.title }</h3>
									<p>
										<strong>What is the main goal for this project?</strong><br></br>
										This project aims to use data collection with field work to create a model to improve the performance of RMK mics, a device used to receive and deliver sound.
									</p>
									<p>
										We made a smartphone app called HeardThat which uses AI to enhance speech so that the app user can hear more easily in noisy areas. Understanding speech in noise is the most common complaint of adults with hearing loss, even if it is mild loss, and whether or not they have hearing aids. Hearing loss affects about 20% of the world's population.
									</p>
									<p>
										Our HeardThat product has been used over a million times. It uses the microphones in a person's smartphone to gather environmental sound and then uses AI running on the phone to remove the noise.
									</p>
									<p>
										To increase the number of use cases where our technology can be used, we have recently introduced the HeardThat Remote Mic Kit (RMK). It consists of two wireless clip-on microphones and a receiver that plugs into the phone.
									</p>
									<p>
										The AI algorithms that HeardThat uses have been optimized for audio coming from a phone's built-in mics. The mics in the RMK have somewhat different characteristics. The goal of this project is to gather data from RMK mics and use it to fine tune the existing AI models.
									</p>
									<p>
										A stretch goal is to investigate synthesizing data for training the models. Such data needs to be as realistic as possible to match the characteristics of the mics and room effects (such as reverb). The goal is to recommend the best approaches.
									</p>
									<p className="mb-0">
										<strong>What tasks will learners need to complete to achieve the project goal?</strong><br></br>
										Data collection
									</p>
									<ul>
										<li>
											This amounts to taking a few phones running our app to noisy locations like coffee shops, putting the app into record mode, and chilling out while it records the ambient background noise.
										</li>
										<li>
											A secondary activity is to record people talking while using the app with the RMK. This will include listening in real time and observing the effectiveness of the noise removal.
										</li>
									</ul>
									<p className="mb-0">
										Assess and improve model quality
									</p>
									<ul>
										<li>
											Using the collected data, establish a baseline for model quality using our existing models.
										</li>
										<li>
											Fine tune the existing models using the collected data to improve the performance when using RMK mics.
										</li>
									</ul>
									<p className="mb-0">
										Assess data synthesis techniques (if time permits)
									</p>
									<ul>
										<li>
											Assess different ways of doing the synthesis to select the best approaches.
										</li>
									</ul>
									<p className="mb-0">
										Deliverables
									</p>
									<ul>
										<li>
											Collected data.
										</li>
										<li>
											Any related project artifacts such as code, GitHub issues, etc.
										</li>
										<li>
											A report describing methodology and conclusions.
										</li>
									</ul>
									<p className="mb-0">
										<strong>How will you support learners in completing the project?</strong><br></br>
										We will be able to provide answers to questions such as:
									</p>
									<ul>
										<li>
											Our current products and applications of AI / ML
										</li>
										<li>
											The current data set and guidance in assessing
										</li>
										<li>
											Current industry standard approaches to AI / ML in our application area
										</li>
										<li>
											Input on choices, problems, or anything else the students might encounter.
										</li>
										<li>
											Guidance on the software tools and techniques to be used
										</li>
										<li>
											Access to our product software and hardware
										</li>
									</ul>
								</div>
							</div>
							<div className="col-lg-5">
								<div className="eduvibe-sidebar">
									<div className="eduvibe-widget eduvibe-widget-details">
										<h5 className="title">ABOUT INDUSTRY PARTNER</h5>
										<div className="widget-content">
											<ul>
												{/* <li><span><i className="icon-Campus"></i> { eventItem.company }</span></li> */}
												<li><span><i className="icon-Campus"></i> <a href={eventItem.location}>{ eventItem.company }</a></span></li>
												<li><span><i className="icon-presentation"></i> { eventItem.category }</span></li>
												{/* <li><span><i className="icon-map-pin-line"></i> {company ? <a href={eventItem.location}>Website</a> : eventItem.location}</span></li> */}
												<li>
													Singular Hearing develops innovative approaches for hearing assistance using AI and Machine Learning to optimize speech and audio technology. One of their flagship products is HeardThat, a smartphone app that turns smartphones into hearing-assistive devices to filter out noise in busy environments.
												</li>
											</ul>
											{!company && <div className="read-more-btn mt--45">
												<a className="edu-btn btn-secondary text-center" href="https://form.jotform.com/m2mtech/wil-programs-sign-up">Register Now<i className="icon-arrow-right-line-right"></i></a>
											</div>}
										</div>
									</div>
								</div>
							</div>
							{!company && <div className="col-lg-12">
								<div className="read-more-btn text-center">
									<a className="edu-btn btn-secondary text-center" href="https://form.jotform.com/m2mtech/wil-programs-sign-up">Register Now<i className="icon-arrow-right-line-right"></i></a>
								</div>
							</div>}
						</div>
					</div>
				</div>
			</Layout>
			{showRegisterForm && <RegisterProgramForm setShowRegisterForm={setShowRegisterForm} />}
		</>
	)
}
export default AIForSpeechEnhancement;