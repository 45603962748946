import React, { useEffect, useRef, useState } from 'react'
import SEO from '../../../common/SEO';
import HeaderTwo from '../../../common/header/HeaderTwo';
import FooterOne from '../../../common/footer/FooterOne';
import JobseekersBanner from '../../../components/data-talent/data-talent-jobseekers/JobseekersBanner';
import JobseekersAbout from '../../../components/data-talent/data-talent-jobseekers/JobseekersAbout';
import DataTalentTestimonialSection from '../../../components/data-talent/data-talent-jobseekers/DataTalentTestimonialSection';
import DataTalentCapstoneProjects from '../../../components/data-talent/data-talent-jobseekers/DataTalentCapstoneProjects';
import DataTalentProgramOverview from '../../../components/data-talent/data-talent-jobseekers/DataTalentProgramOverview';
import DataTalentFooter from '../../../components/data-talent/data-talent-jobseekers/DataTalentFooter';
import DPONavbar from '../../../components/data-talent/data-talent-jobseekers/DPONavbar';
import DPBSNavbar from '../../../components/data-talent/data-talent-jobseekers/DPBSNavbar';
import JobseekersEmployerCTA from '../../../components/data-talent/data-talent-jobseekers/JobseekersEmployerCTA';
import DataTalentFaq from '../../../components/data-talent/data-talent-jobseekers/DataTalentFaq';

const DataTalentJobseekers = () => {

  const [isSticky, setIsSticky] = useState(false);
  const [showTimeline, setShowTimeline] = useState(true);
  const [showBenefits, setShowBenefits] = useState(false);
  const [showCurriculum, setShowCurriculum] = useState(false);
  const [outerEntry, setOuterEntry] = useState(false);
  const [innerEntry, setInnerEntry] = useState(false);
  const [isStickyDPBS, setIsStickyDPBS] = useState(false);
  const [showCapstone, setShowCapstone] = useState(true);
  const [showWIL, setShowWIL] = useState(false);
  const [showBlogs, setShowBlogs] = useState(false);
  const [outerEntryDPBS, setOuterEntryDPBS] = useState(false);
  const [innerEntryDPBS, setInnerEntryDPBS] = useState(false);
  const [activeDPOTab, setActiveDPOTab] = useState("Timeline");
  const [activeDPBSTab, setActiveDPBSTab] = useState("Capstone");

  const targetSectionRef = useRef(null);
  const targetSectionInnerRef = useRef(null);
  const targetSectionRefDPBS = useRef(null);
  const targetSectionInnerRefDPBS = useRef(null);


  const handleTimelineClick = () => {
    document.getElementById("Timeline").classList.add("active-program-overview");
    document.getElementById("Benefits").classList.remove("active-program-overview");
    document.getElementById("Curriculum").classList.remove("active-program-overview");
    document.getElementById("TimelineMobileNavbar")?.classList.add("active-program-overview");
    document.getElementById("BenefitsMobileNavbar")?.classList.remove("active-program-overview");
    document.getElementById("CurriculumMobileNavbar")?.classList.remove("active-program-overview");
    setShowTimeline(true);
    setShowBenefits(false);
    setShowCurriculum(false);
    setActiveDPOTab("Timeline");
  }
  
  const handleBenefitsClick = () => {
    document.getElementById("Timeline").classList.remove("active-program-overview");
    document.getElementById("Benefits").classList.add("active-program-overview");
    document.getElementById("Curriculum").classList.remove("active-program-overview");
    document.getElementById("TimelineMobileNavbar")?.classList.remove("active-program-overview");
    document.getElementById("BenefitsMobileNavbar")?.classList.add("active-program-overview");
    document.getElementById("CurriculumMobileNavbar")?.classList.remove("active-program-overview");
    setShowTimeline(false);
    setShowBenefits(true);
    setShowCurriculum(false);
    setActiveDPOTab("Benefits");
  }
  
  const handleCurriculumClick = () => {
    document.getElementById("Timeline").classList.remove("active-program-overview");
    document.getElementById("Benefits").classList.remove("active-program-overview");
    document.getElementById("Curriculum").classList.add("active-program-overview");
    document.getElementById("TimelineMobileNavbar")?.classList.remove("active-program-overview");
    document.getElementById("BenefitsMobileNavbar")?.classList.remove("active-program-overview");
    document.getElementById("CurriculumMobileNavbar")?.classList.add("active-program-overview");
    setShowTimeline(false);
    setShowBenefits(false);
    setShowCurriculum(true);
    setActiveDPOTab("Curriculum");
  }

  const handleCapstoneClick = () => {
    document.getElementById("Capstone").classList.add("active-program-overview");
    document.getElementById("WILs").classList.remove("active-program-overview");
    document.getElementById("Blogs").classList.remove("active-program-overview");
    document.getElementById("CapstoneMobileNavbar")?.classList.add("active-program-overview");
    document.getElementById("WILMobileNavbar")?.classList.remove("active-program-overview");
    document.getElementById("BlogsMobileNavbar")?.classList.remove("active-program-overview");
    setShowCapstone(true);
    setShowWIL(false);
    setShowBlogs(false);
    setActiveDPBSTab("Capstone");
  }
  
  const handleWILClick = () => {
    document.getElementById("Capstone").classList.remove("active-program-overview");
    document.getElementById("WILs").classList.add("active-program-overview");
    document.getElementById("Blogs").classList.remove("active-program-overview");
    document.getElementById("CapstoneMobileNavbar")?.classList.remove("active-program-overview");
    document.getElementById("WILMobileNavbar")?.classList.add("active-program-overview");
    document.getElementById("BlogsMobileNavbar")?.classList.remove("active-program-overview");
    setShowCapstone(false);
    setShowWIL(true);
    setShowBlogs(false);
    setActiveDPBSTab("WILs");
  }
  
  const handleBlogsClick = () => {
    document.getElementById("Capstone").classList.remove("active-program-overview");
    document.getElementById("WILs").classList.remove("active-program-overview");
    document.getElementById("Blogs").classList.add("active-program-overview");
    document.getElementById("CapstoneMobileNavbar")?.classList.remove("active-program-overview");
    document.getElementById("WILMobileNavbar")?.classList.remove("active-program-overview");
    document.getElementById("BlogsMobileNavbar")?.classList.add("active-program-overview");
    setShowCapstone(false);
    setShowWIL(false);
    setShowBlogs(true);
    setActiveDPBSTab("Blogs");
  }

  useEffect(() => {
    if(outerEntry) {
      if(!innerEntry) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    } else {
      setIsSticky(false);
    }
  }, [outerEntry, innerEntry])
  
  useEffect(() => {
    if(outerEntryDPBS) {
      if(!innerEntryDPBS) {
        setIsStickyDPBS(true);
      } else {
        setIsStickyDPBS(false);
      }
    } else {
      setIsStickyDPBS(false);
    }
  }, [outerEntryDPBS, innerEntryDPBS])

  useEffect(() => {
    let targetInnerCurrent = targetSectionInnerRef.current;
    let targetCurrent = targetSectionRef.current;
    
    const innerObserver = new IntersectionObserver(
      ([innerEntry]) => {
        if(innerEntry.isIntersecting) {
          setInnerEntry(true);
        } else {
          setInnerEntry(false);
        }
      },
      { rootMargin: "-135px 0px 0px 0px", threshold: 0.1 }
    );
    
    const observer = new IntersectionObserver(
      ([entry]) => {
        if(entry.isIntersecting) {
          setOuterEntry(true);
        } else {
          setOuterEntry(false);
        }
      },
      { threshold: 0.1 }
    );

    let targetInnerCurrentDPBS = targetSectionInnerRefDPBS.current;
    let targetCurrentDPBS = targetSectionRefDPBS.current;
    
    const innerObserverDPBS = new IntersectionObserver(
      ([innerEntry]) => {
        if(innerEntry.isIntersecting) {
          setInnerEntryDPBS(true);
        } else {
          setInnerEntryDPBS(false);
        }
      },
      { rootMargin: "-135px 0px 0px 0px", threshold: 0.1 }
    );
    
    const observerDPBS = new IntersectionObserver(
      ([entry]) => {
        if(entry.isIntersecting) {
          setOuterEntryDPBS(true);
        } else {
          setOuterEntryDPBS(false);
        }
      },
      { threshold: 0.1 }
    );
    
    if (targetInnerCurrent) {
      innerObserver.observe(targetInnerCurrent);
    }

    if (targetCurrent) {
      observer.observe(targetCurrent);
    }
    
    if (targetInnerCurrentDPBS) {
      innerObserverDPBS.observe(targetInnerCurrentDPBS);
    }

    if (targetCurrentDPBS) {
      observerDPBS.observe(targetCurrentDPBS);
    }

    return () => {
      if (targetCurrent) {
        observer.unobserve(targetCurrent);
      }
      if (targetInnerCurrent) {
        innerObserver.unobserve(targetInnerCurrent);
      }
      if (targetCurrentDPBS) {
        observerDPBS.unobserve(targetCurrentDPBS);
      }
      if (targetInnerCurrentDPBS) {
        innerObserverDPBS.unobserve(targetInnerCurrentDPBS);
      }
    };
  }, []);
  
  return (
    <>
      <SEO
				title="DataTalent for Jobseekers"
				description="Build your career in Data Analytics, ML and AI. Join our comprehensive 16 week online program designed to equip you with advanced skills in Data Science, Machine Learning and AI aligned with Industry Oriented Curriculum."
      />
      <HeaderTwo styles="header-transparent header-style-2" searchDisable />
      <DPONavbar
        isSticky={isSticky}
        handleTimelineClick={handleTimelineClick}
        handleBenefitsClick={handleBenefitsClick}
        handleCurriculumClick={handleCurriculumClick}
        activeDPOTab={activeDPOTab}
      />
      <DPBSNavbar
        isStickyDPBS={isStickyDPBS}
        handleCapstoneClick={handleCapstoneClick}
        handleWILClick={handleWILClick}
        handleBlogsClick={handleBlogsClick}
        activeDPBSTab={activeDPBSTab}
      />
      <JobseekersBanner />
      <JobseekersEmployerCTA />
      <JobseekersAbout />
      <DataTalentProgramOverview
        targetRef={targetSectionRef}
        innerRef={targetSectionInnerRef}
        showTimeline={showTimeline}
        showBenefits={showBenefits}
        showCurriculum={showCurriculum}
        handleTimelineClick={handleTimelineClick}
        handleBenefitsClick={handleBenefitsClick}
        handleCurriculumClick={handleCurriculumClick}
      />
      <DataTalentTestimonialSection />
      <DataTalentCapstoneProjects
        targetRefDPBS={targetSectionRefDPBS}
        innerRefDPBS={targetSectionInnerRefDPBS}
        showCapstone={showCapstone}
        showWIL={showWIL}
        showBlogs={showBlogs}
        handleCapstoneClick={handleCapstoneClick}
        handleWILClick={handleWILClick}
        handleBlogsClick={handleBlogsClick}
      />
      <DataTalentFooter />
      <DataTalentFaq />
      <FooterOne />
    </>
  )
}

export default DataTalentJobseekers;