export const ProgramsData = [
    {
        "id": 1,
        "title": "DataTalent",
        "info": "The DataTalent program offers a comprehensive technical upskilling initiative that combines employer-led work-integrated learning projects, professional development prospects, and industry exposure.",
        "numberOfCourses": "16 weeks",
        "modeOfCourses": "Anywhere",
        "category": "Data Science",
        "image": "project-details/data-analyst_thumbnail.webp",
        "link": "/programs/datatalent/jobseekers",
        "form": "datatalent"
    },
    {
        "id": 2,
        "title": "SkillsBoost",
        "info": "Unlock the future of technology with our comprehensive program designed to help participants gain industry-aligned certifications that are sought after in Data Science, ML, and AI.",
        "numberOfCourses": "12 months",
        "modeOfCourses": "Anywhere",
        "category": "Data Science, ML and AI",
        "image": "project-details/skillsboost_v3_thumbnail.webp",
        "link": "/programs/membership/skillsboost",
        "form": "skillsboost"
    },
    {
        "id": 3,
        "title": "AIReady",
        "info": "During your 12-month membership, gain access to a library of courses and complete paid projects with our employer partners. Elevate your resume, increase your toolkit and expand your project portfolio!",
        "numberOfCourses": "12 months",
        "modeOfCourses": "Anywhere",
        "category": "Data Science, ML and AI",
        "image": "project-details/industry_wil_v2.webp",
        "link": "/programs/membership/aiready",
        "form": "aiready",
        "attribution" : "Image by freepik",
        "attributionLink" : "https://www.freepik.com/free-photo/person-using-ai-tool-job_60368920.htm#fromView=search&page=1&position=3&uuid=b2857b87-be43-42b6-8d53-82c6017ca5a0"
    },
    {
        "id": 4,
        "title": "CareerLaunch",
        "info": "For those looking to work in tech, further your learning with our vast library of courses and add to your resume with a guaranteed internship in data science.",
        "numberOfCourses": "12 months",
        "modeOfCourses": "Anywhere",
        "category": "Data Science, ML & AI",
        "image": "project-details/careerlaunch_img.webp",
        "link": "/programs/membership/careerlaunch",
        "form": "careerlaunch"
    }
    // {
    //     "id": 3,
    //     "title": "Industry Partner Work Integrated Learning",
    //     "info": "Collaborate with Industry partners and professionals and gain invaluable experience in the field of Data Science, ML, AI.",
    //     "numberOfCourses": "12 weeks",
    //     "modeOfCourses": "Anywhere",
    //     "category": "Data Science, ML and AI",
    //     "image": "project-details/industry_wil_v2.webp",
    //     "link": "/programs/work-integrated-learning",
    //     "form": "wil",
    //     "attribution" : "Image by freepik",
    //     "attributionLink" : "https://www.freepik.com/free-photo/person-using-ai-tool-job_60368920.htm#fromView=search&page=1&position=3&uuid=b2857b87-be43-42b6-8d53-82c6017ca5a0"
    // },
    // {
    //     "id": 4,
    //     "title": "Health Sciences Data Analytics",
    //     "info": "Develop a pilot project to illustrate the role of multi-edge computing in Neuraura's long-term vision for LoOoP.",
    //     "numberOfCourses": "12 weeks",
    //     "modeOfCourses": "Anywhere",
    //     "category": "Data Science, ML & AI",
    //     "image": "project-details/health_sciences_data_analytics_thumb.webp",
    //     "link": "/programs/work-integrated-learning/health-sciences-data-analytics"
    // }
]
