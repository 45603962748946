import React from 'react'
import { Element } from 'react-scroll';
import AMSectionTitle from '../AMSectionTitle';
import { Link } from 'react-router-dom';

const MembershipSkillsboostAbout = () => {
  const skillsboostBenefits = [
    {
      image: "courses.svg",
      title: "Access to Online Courses",
      description: "24/7 access to courses in Data Science, Machine Learning, and AI."
    },
    {
      image: "certificate.svg",
      title: "Industry-Recognized Certification",
      description: "Earn a certification to enhance your resume and show your skills."
    },
    {
      image: "ai_resume_builder.svg",
      title: "AI Resume Builder",
      description: "Access exclusive job listings in Data Science, Machine Learning, and AI."
    },
    {
      image: "workshops.svg",
      title: "Webinars and Workshops",
      description: "Join live webinars and workshops to build essential career skills."
    },
    {
      image: "community.svg",
      title: "Community Access",
      description: "Connect with peers and mentors in an active online community."
    }
  ]

  return (
    <>
      <Element className="edu-about-area bg-shade datatalent-section-gap">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 mt-0 p-0">
              <AMSectionTitle
                classes = "text-left"
                // slogan = "Introduction"
                title = "What's included in Skillsboost?"
              />
            </div>
          </div>
          <div className="row g-5">
            <div className="col-12 p-0">
              <p className="ms-about-description">
                The SkillsBoost program provides you with the fundamental knowledge and tools to kickstart your tech career. 
              </p>
              <p className="ms-about-description">
                Designed for learners seeking flexibility, this self-paced program allows you to gain in-demand skills, earn industry-recognized certification, and connect with experts. 
              </p>
              <p className="ms-about-description-highlight">
                <strong>Skillsboost is tailored specifically for beginners to learn technical data skills and complete industry level certifications.</strong>
              </p>
            </div>
            <div className="col-12">
              <div className="skillsboost-benefits-card-container d-flex flex-row flex-wrap justify-content-center p-0">
                {skillsboostBenefits.map((skillsboostBenefit, idx) => (<div className="col-lg-4" key={idx}>
                  <div className="skillsboost-benefits-card mx-0 mx-md-2">
                    <img src={`${process.env.PUBLIC_URL}/images/annual-membership/icons/${ skillsboostBenefit.image }`} alt="illustration" />
                    <p className="skillsboost-benefits-card-header my-2">{skillsboostBenefit.title}</p>
                    <p className="mb-0">{skillsboostBenefit.description}</p>
                  </div>
                </div>))}
              </div>
            </div>
            <div className="col-12 mt--80">
              <div className="row g-5 position-relative ba-footer">
                <div className="col-lg-7 p-0 mt-0">
                  <h3 className="color-white">Ready to take the next step in<br></br>your career and become a<br></br>Data Science Expert?</h3>
                </div>
                <div className="col-lg-2 d-none d-md-block"></div>
                <div className="col-lg-3 p-0 justify-content-center align-items-center d-none d-md-flex">
                  <a className="edu-btn datatalent-cta" href="https://courses.m2mtechconnect.com/enroll/3134895">Register Now</a>
                </div>
                <p className="mb-0 p-0 d-block d-md-none">Join M2Ms Business Accelerator to unlock the potential of AI<br></br> and drive impactful change in your industry.</p>
                <Link to="/contact-us" className="d-flex d-md-none gap-2 mt-3 p-0"><span className="highlight-text-primary"><em>Chat with one of our experts to learn more</em></span> <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/maki_arrow.svg`} alt="right arrow" /></Link>
                <div className="col-lg-3 p-0 justify-content-center align-items-center d-flex d-md-none">
                  <a className="edu-btn datatalent-cta" href="/contact-us">Apply Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default MembershipSkillsboostAbout;